<template>
  <div>
    <section class="listings">
      <ProfileRingTabs class="py-3" :tabs="tabs" :pressed="currentTab" @tab-changed="tabChanged" />
      <div class="py-3">
        <SearchInput
          v-model="text"
          :search-icon="true"
          :shortcut-icon="false"
          :clear-icon="false"
          :hide-shortcut-icon-on-blur="true"
          :clear-on-esc="false"
          :blur-on-esc="true"
          :select-on-focus="false"
          :shortcut-listener-enabled="true"
          placeholder="Aa"
          @input="onSearch"
        />
      </div>

      <ChLoading size="lg" class="mx-auto" v-if="loading" />

      <div v-else>
        <div v-if="currentTab === 'featured'">
          <ProfileRingsSection
            :items="ringCategories"
            :white-card-color="true"
            :amountSection="true"
            @updated="fetchUpdatedPrfRingCategories"
            @fetch="fetchRingCategories"
          />
        </div>
        <div v-else>
          <template v-for="(category, key) in subCategories" :key="key">
            <div v-if="!isEmpty(category)">
              <div class="title">{{ key }}</div>
              <ProfileRingsSection
                :items="category"
                :white-card-color="true"
                :amountSection="true"
                @updated="fetchUpdatedPrfRingCategories"
                @fetch="fetchRingCategories"
              />
            </div>
          </template>
          <div v-if="isEmpty(ringCategories) && !pageLoad" class="no-data">No Rings Yet!</div>
        </div>
        <ChLoading size="md" v-if="pageLoad" class="mx-auto" />
        <p v-else-if="nextPageExists && !pageLoad" class="clickable text-center" @click="requestLoadMore">Load More</p>

        <template v-for="(category, index) in profileRingSetCategories" :key="category.id">
          <div v-if="shouldDisplayCategory(category)" :style="getCategoryStyle(category)" class="mt-3 p-2 mb-2">
            <div class="set-title">{{ category.name }}</div>
            <div class="d-flex align-items-center justify-content-end mb-2">
              <ion-button class="unlock-btn" :style="getUnlockButtonStyle(category)">
                <i class="ti-unlock mr-1" /> Unlocked: {{ get(category, 'unlocked_count') }}/{{
                  get(category, 'total_count')
                }}
              </ion-button>
            </div>
            <ProfileRingsSection
              :items="category.scheduler_items"
              :white-card-color="true"
              :amountSection="true"
              :schedulerId="category.id"
              :unlockedCounts="category.unlocked_count"
              @success="fetchUpdatedCustomSets"
              @get="fetchCustomSets"
              @fetch="fetchStoreCustomSets"
            />
          </div>
        </template>

        <client-only>
          <div class="d-flex justify-content-center">
            <infinite-loading v-if="get(customPaging, 'next')" @infinite="loadHandler">
              <template #complete>
                <span></span>
              </template>
              <template #spinner>
                <div class="d-flex justify-content-center">
                  <ChLoading v-if="isTotalLoadMore" size="sm" class="spinner" />
                </div>
              </template>
            </infinite-loading>
          </div>
        </client-only>
      </div>
    </section>
  </div>
</template>

<script lang="ts" setup>
import SearchInput from 'vue-search-input';
import ProfileRingsSection from '@/shared/pages/profile/components/VirtualItemsSection.vue';
import ProfileRingTabs from '@/shared/pages/store/ProfileRingTabs.vue';
import { Paging, Tab } from '@/shared/types/static-types';
import { profileRingsTabs } from '@/shared/statics/tabs';
import { getNextPage, getShopVirtualItems, getVirtualItemsCustomSet } from '@/shared/actions/virtualItems';
import { toast } from '~/shared/native';
import InfiniteLoading from 'v3-infinite-loading';
const props = defineProps({
  profileRingCategories: { type: Array, default: [] },
});

const emits = defineEmits(['updated', 'search']);
const currentTab = ref('featured');
const tabIndex = ref(0);
const currentCustomPage = ref(1);
const customPaging: any = ref<Paging>();
const currentPage = ref(1);
const profileRingCategories: any = toRef(props, 'profileRingCategories');
const text = ref('');
const isTotalLoadMore = ref(false);
const router = useRouter();
const loading = ref(true);
const pageLoad = ref(false);
const tabs = ref(profileRingsTabs);
const route = useRoute();
const customPageLoad = ref(false);
const ringCategories: any = ref({});
const profileRingSetCategories: any = ref([]);
const ringPaging: any = ref<Paging>();
const getTabIndex = (text: any) => {
  const tIndex = indexOf(
    tabs.value,
    find(tabs.value, (tab) => tab.value === text)
  );
  if (tIndex >= 0) {
    tabIndex.value = tIndex;
    currentTab.value = tabs.value[tabIndex.value].value;
  }
};

const hasInStoreTill = computed(() => {
  const now = new Date().getTime();
  const schedulerItems = flatMap(profileRingSetCategories.value, (category) => get(category, 'scheduler_items', []));
  for (const item of schedulerItems) {
    const inStoreTill = new Date(item.in_store_till).getTime();
    if (now <= inStoreTill) {
      return true;
    }
  }
  return false;
});

const tabChanged = ({ value }: Tab) => {
  currentTab.value = value;
  getTabIndex(currentTab.value);
  router.replace({ name: 'shop', query: { tab: 'profile-rings-shop', title: currentTab.value } });
};
watch(
  profileRingCategories,
  () => {
    const otherCategories = profileRingCategories.value.map((item: any) => {
      const value = item.title.toLowerCase().replace(/ /g, '_');
      return { name: item.title, value: value };
    });
    tabs.value = tabs.value.concat(otherCategories);

    getTabIndex(route.query.title);
  },
  { immediate: true }
);

const fetchRingCategories = async (page = 1) => {
  pageLoad.value = true;
  const searchCriteria: any = { page, page_size: 40, ...(text.value && { search: text.value }) };
  if (currentTab.value === 'all') '';
  else if (currentTab.value === 'featured') searchCriteria.is_featured = true;
  else {
    searchCriteria.main_category__title = tabs.value[tabIndex.value].name;
  }

  const { results, ...paging } = await getShopVirtualItems(searchCriteria);
  ringCategories.value = page === 1 ? results : ringCategories.value.concat(results);
  if (ringCategories.value) {
    ringCategories.value = ringCategories.value.filter(
      (val: any) =>
        isNull(get(val, 'in_store_till')) || new Date().getTime() <= new Date(get(val, 'in_store_till')).getTime()
    );
  }
  ringPaging.value = paging;
  currentPage.value = page;
  pageLoad.value = false;
  loading.value = false;
};

const fetchUpdatedCustomSets = (id: any, schedulerID: any) => {
  const category = profileRingSetCategories.value.find((item: any) => item.id === schedulerID);
  if (category) {
    const item = category.scheduler_items.find((item: any) => item.id === id);
    if (item && !item.is_unlocked) {
      item.is_unlocked = true;
      if (category.unlocked_count < category.total_count) {
        category.unlocked_count += 1;
      }
    }
  }
};
const fetchStoreCustomSets = () => {
  profileRingSetCategories.value = profileRingSetCategories.value.map((category: any) => {
    category.scheduler_items = category.scheduler_items.filter(
      (val: any) => new Date().getTime() <= new Date(val.in_store_till).getTime()
    );
    return category;
  });
};
const subCategories = computed(() => {
  return ringCategories.value.reduce((acc: any, item: any) => {
    const subCategoryTitle = get(item.sub_category, 'title') || 'Others';
    acc[subCategoryTitle] = (acc[subCategoryTitle] || []).concat(item);
    return acc;
  }, {});
});

const fetchCustomSets = async (page = 1) => {
  customPageLoad.value = true;
  const { results, ...paging } = await getVirtualItemsCustomSet({ page, page_size: 6 });
  profileRingSetCategories.value = results;
  customPaging.value = paging;
  currentCustomPage.value = page;
  customPageLoad.value = false;
};

const nextPageExists = computed(() => {
  return !!get(ringPaging.value, 'next');
});

const requestLoadMore = async (ev: CustomEvent) => {
  if (!ringPaging.value.next) {
    (ev?.target as any).complete();
  } else {
    await fetchRingCategories(currentPage.value + 1);
  }
};

watch(currentTab, async () => {
  if (currentTab.value) {
    ringCategories.value = [];
    text.value = '';
    fetchRingCategories();
  }
});

const fetchUpdatedPrfRingCategories = () => {
  emits('updated');
  fetchRingCategories();
};

const requestCustomLoadMore = async () => {
  isTotalLoadMore.value = true;
  try {
    const { results, ...paging } = await getNextPage(customPaging.value!);
    profileRingSetCategories.value = profileRingSetCategories.value.concat(results);
    customPaging.value = paging;
  } catch (e) {
    await toast.show('Could not load. Please try again.', 'nonative', 'danger');
  } finally {
    isTotalLoadMore.value = false;
  }
};

const loadHandler = ($state: any) => {
  if (customPaging.value.next) {
    requestCustomLoadMore();
  } else {
    $state.complete();
  }
};

const searchTextChanged = debounce(async () => {
  await fetchRingCategories();
}, 500);

const onSearch = () => {
  searchTextChanged();
};

const shouldDisplayCategory = (category: any) => {
  return (
    !isEmpty(profileRingSetCategories.value) &&
    !isEmpty(get(category, 'scheduler_items')) &&
    ['all', 'featured'].includes(currentTab.value) &&
    hasInStoreTill.value
  );
};

const getCategoryStyle = (category: any) => {
  const baseStyle = 'border: 2px solid; border-radius: 18px';
  const borderColor = category.unlocked_count === category.total_count ? '#A0CF3D' : '#ae38e5';
  return `${baseStyle}; border-color: ${borderColor}`;
};

const getUnlockButtonStyle = (category: any) => {
  const unlockedStyle = '--background: linear-gradient(90deg, #D0DE2F 0%, rgba(212,226, 56, 0) 100%), #17A561';
  return category.unlocked_count === category.total_count ? unlockedStyle : '';
};
onMounted(async () => {
  fetchRingCategories();
  fetchCustomSets();
});
</script>

<style lang="sass" scoped>
.unlock-btn
  --border-radius: 20px
  --background: linear-gradient(180deg, #AE38E5 0%, #7216E8 100%)
.set-title
  font-size: 20px
  color: #431C89
  font-weight: bold
.dark .set-title
  color: #fff !important

.title
  font-size: 20px
  color: #214163
  font-weight: bold
</style>
